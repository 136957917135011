<template>
  <div>
    <staticResourceTabs :isValid="isValid" v-on="$listeners" :type="valueLine.target.type">
      <template v-slot:tabsContent="data">
        <div class="mb-n9" v-if="data.item == 'static' && valueLine.target.type == 'static'">
          <iperfStaticContent :localStoredStep="valueLine" v-on="$listeners" :result="result" :isValid="isValid"
                              :hideDetails="false"/>
        </div>
        <div class="mb-n9" v-if="data.item == 'resource' && valueLine.target.type == 'resource'">
          <iperfResourceContent :localStoredStep="valueLine" v-on="$listeners" :result="result" :isValid="isValid"
                                :hideDetails="false"/>
        </div>
      </template>
    </staticResourceTabs>
    <div>
      <fieldsRows :templateContent="templateContent" :valueLine="valueLine" :isValid="isValid" v-on="$listeners"/>
    </div>
  </div>
</template>
<script>
import staticResourceTabs from '@/components/legacy/staticResourceTabs.vue';
import iperfStaticContent from '@/components/legacy/iperfStaticContent.vue';
import iperfResourceContent from '@/components/legacy/iperfResourceContent.vue';
import fieldsRows from '@/commonComponents/fieldsRows.vue';

export default {
  data() {
    return {
      //attrSource: "t_testcases",
      //cachedParametersAttributes: []
    };
  },
  components: {
    staticResourceTabs,
    iperfStaticContent,
    iperfResourceContent,
    fieldsRows,
  },
  props: {
    result: {},
    field: String,
    isValid: Boolean,
    valueLine: {},
    value: {},
  },
  methods: {},
  computed: {
    templateContent() {
      let templateContent = {
        //TO DO
        fields: {
          testDirection: {
            inputFieldFormatter: 'iperfDirection',
          },
        },
        // stepType: this.stepType,
        // stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        enableAdd: false,
        contentID: this.result.contentID,
      };
      return templateContent;
    },
  },
};
</script>