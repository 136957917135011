<template>
  <div>
    <v-row>
      <v-col cols="2" class="">
        <associatedOption
            :fieldAttributes="{name: 'IP Version', associatedOption: localIpv, value: 'ipv4'}" type="select"
            :fieldAttrInput="{class: 'required'}" v-model="ipVersion" :templateContent="result" :isValid="isValid"
            field="ip"/>
      </v-col>
      <v-col cols="2" class="">
        <associatedOption :fieldAttributes="{name: 'Transport Protocol', associatedOption: localProtocol, value: 'tcp'}"
                          type="select" :fieldAttrInput="{class: 'required'}" v-model="transportProtocol"
                          :templateContent="result" :isValid="isValid" field="protocol" v-on="$listeners"/>
      </v-col>
      <v-col cols="4">
        <!-- <primaryTextfield
          type="text"
          :fieldAttrInput="{ class: inputClass }"
          label="Host"
          v-model="host"
          :templateContent="result"
          :isValid="isValid"
        ></primaryTextfield> -->
        <ip4ip6ValidatedInput field="host" :fieldAttributes="{name: 'Host'}" v-model="host"
                              :valueLine="this.localStoredStep.target" :result="result" :isValid="isValid"
                              :required="this.localStoredStep.target.type === 'static'" v-on="$listeners"/>
      </v-col>
      <v-col cols="2">
        <primaryTextfield type="text" :fieldAttrInput="{class: 'number'}" label="Port" v-model="port"
                          :templateContent="result" :isValid="isValid" name="port"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import primaryTextfield from '@/commonComponents/primaryTextfield.vue';
import associatedOption from '@/commonComponents/associatedOption.vue';
import ip4ip6ValidatedInput from '@/components/dynamic/ip4ip6ValidatedInput.vue';

export default {
  props: ['localStoredStep', 'result', 'isValid'],
  data: function () {
    return {
      localIpv: [
        ['ipv4', 'IPv4'],
        ['ipv6', 'IPv6'],
      ],
      localProtocol: [
        ['tcp', 'TCP'],
        ['udp', 'UDP'],
      ],
    };
  },
  components: {
    primaryTextfield,
    associatedOption,
    ip4ip6ValidatedInput,
  },
  // watch: {
  //   ipVersion() {
  //     let tmp = this.host;
  //     this.host = "";
  //     this.$nextTick(function () {
  //       this.host = tmp;
  //     });
  //   },
  // },
  computed: {
    // inputClass() {
    //   let ic = "required";
    //   if (this.ipVersion == "ipv4") {
    //     ic += " IPaDomainaVariable";
    //   } else if (this.ipVersion == "ipv6") {
    //     ic += " IP6aDomainaVariable";
    //   }
    //   return ic;
    // },
    ipVersion: {
      get() {
        let ipVersion = 'ipv4';
        if (typeof this.localStoredStep.target.ip != 'undefined') {
          ipVersion = this.localStoredStep.target.ip;
        }
        return ipVersion;
      },
      set(selected) {
        this.$set(this.localStoredStep.target, 'ip', selected);
      },
    },
    transportProtocol: {
      get() {
        let tProtocol = 'tcp';
        if (typeof this.localStoredStep.target.protocol != 'undefined') {
          tProtocol = this.localStoredStep.target.protocol;
        }
        return tProtocol;
      },
      set(selected) {
        this.$set(this.localStoredStep.target, 'protocol', selected);
      },
    },
    // evalValue: {
    //   get() {
    //     let evalValue = {};
    //     // console.log(this.localStoredStep);
    //     if (
    //       typeof this.localStoredStep != "undefined" &&
    //       typeof this.localStoredStep["target"] != "undefined"
    //     ) {
    //       evalValue = this.localStoredStep["target"];
    //     }
    //     return evalValue;
    //   },
    //   set(newVal) {
    //     this.updateResponseKey("target", newVal);
    //   },
    // },
    host: {
      get() {
        let host = '';
        if (typeof this.localStoredStep.target.host != 'undefined') {
          host = this.localStoredStep.target.host;
        }
        return host;
      },
      set(newVal) {
        this.$set(this.localStoredStep.target, 'host', newVal);
        this.updateResponseKey();
      },
    },
    port: {
      get() {
        let port = '';
        if (typeof this.localStoredStep.target.port != 'undefined') {
          port = this.localStoredStep.target.port;
        }
        return port;
      },
      set(newVal) {
        this.$set(this.localStoredStep.target, 'port', newVal);
        this.updateResponseKey();
      },
    },
    tos: {
      get() {
        let tos = '';
        if (typeof this.localStoredStep.target.tos != 'undefined') {
          tos = this.localStoredStep.target.tos;
        }
        return tos;
      },
      set(newVal) {
        this.$set(this.target, 'tos', newVal);
        this.updateResponseKey('target', this.target);
      },
    },
  },
  methods: {
    updateResponseKey() {
      // console.log("STAIC", index, newVal);
      //   this.$set(this.localStoredStep, index, { ...newVal });
      // if (typeof this.evalValue.resourceType != "undefined") {
      // } else {
      //   this.$set(this.localStoredStep, index, {
      //     ...newVal,
      //     type: "static",
      //   });
      // }
      console.log(this.localStoredStep);
      this.$emit('stored-value-changed', this.localStoredStep);
    },
  },
};
</script>